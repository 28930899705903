import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';




@Component({
  selector: 'app-referans',
  templateUrl: './referans.component.html',
  styleUrls: ['./referans.component.css']
})
export class ReferansComponent implements OnInit {
  items: Observable<any[]>;
  
  
  constructor(db: AngularFirestore) {
    this.items = db.collection('musteri_isler').valueChanges();
    
   }
  ngOnInit() {
    
  }

}
