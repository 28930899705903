import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-urunler',
  templateUrl: './urunler.component.html',
  styleUrls: ['./urunler.component.css']
})
export class UrunlerComponent implements OnInit {
  urunler$;
  selectedProduct;
  active:number;
  constructor(private db: AngularFirestore) {
    this.urunler$ = db.collection('urunler').valueChanges();
  }

  ngOnInit() {
  }

  selectProduct(urun: any) {
    this.selectedProduct = urun;
   // console.log("SELECTED",urun)
  }

  onClick(index:number){
    this.active = index;
  }

}
