import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {FooterComponent} from './footer/footer.component';
import {AnasayfaComponent} from './anasayfa/anasayfa.component';
import {SliderComponent} from './slider/slider.component';
import {TeknikbilgiComponent} from './teknikbilgi/teknikbilgi.component';
import {KullaniciGirisComponent} from './kullanici-giris/kullanici-giris.component';
import {GirisComponent} from './kullanici-giris/giris/giris.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {ReferansComponent} from './referans/referans.component';
import {ReferansHelpComponent} from './referans/referans-help/referans-help.component';
import {ReferansInsaatComponent} from './referans-insaat/referans-insaat.component';
import {VizyonComponent} from './vizyon/vizyon.component';
import {UrunlerComponent} from './urunler/urunler.component';
import {UrunDetayComponent} from './urun-detay/urun-detay.component';
import { IletisimComponent } from './iletisim/iletisim.component';

var firebaseConfig = {
  apiKey: "AIzaSyDh-ZBQwg-AzcpIK3XXvvg9xRspCHqda7U",
  authDomain: "kulunkoglu-web.firebaseapp.com",
  databaseURL: "https://kulunkoglu-web.firebaseio.com",
  projectId: "kulunkoglu-web",
  storageBucket: "kulunkoglu-web.appspot.com",
  messagingSenderId: "532104948659",
  appId: "1:532104948659:web:3002fe56f931c165fcb9c6",
  measurementId: "G-SG5CD9CXWW"
};

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    AnasayfaComponent,
    SliderComponent,
    TeknikbilgiComponent,
    KullaniciGirisComponent,
    GirisComponent,
    ReferansComponent,
    ReferansHelpComponent,
    ReferansInsaatComponent,
    VizyonComponent,
    UrunlerComponent,
    UrunDetayComponent,
    IletisimComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
