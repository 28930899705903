import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vizyon',
  templateUrl: './vizyon.component.html',
  styleUrls: ['./vizyon.component.css']
})
export class VizyonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
