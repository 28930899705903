import {Component, Input, OnInit} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-urun-detay',
  templateUrl: './urun-detay.component.html',
  styleUrls: ['./urun-detay.component.css']
})
export class UrunDetayComponent implements OnInit {
  detail$;
  @Input() product: string;
  constructor(private db: AngularFirestore, private route: ActivatedRoute) {
    console.log("productId",this.product)
  }

  ngOnInit(): void {
  }
}
