import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-referans-insaat',
  templateUrl: './referans-insaat.component.html',
  styleUrls: ['./referans-insaat.component.css']
})
export class ReferansInsaatComponent implements OnInit {
  items: Observable<any[]>;

  constructor(db: AngularFirestore) {
    this.items = db.collection('insaat_isler').valueChanges();
  }

  ngOnInit() {
    (function ($){
      $(document).ready(function () {
        $("#single_1").fancybox({
          helpers: {
            title: {
              type: 'float'
            }
          }
        });
  
        $("#single_2").fancybox({
          openEffect: 'elastic',
          closeEffect: 'elastic',
  
          helpers: {
            title: {
              type: 'inside'
            }
          }
        });
  
        $("#single_3").fancybox({
          openEffect: 'none',
          closeEffect: 'none',
          helpers: {
            title: {
              type: 'outside'
            }
          }
        });
  
        $("#single_4").fancybox({
          helpers: {
            title: {
              type: 'over'
            }
          }
        });
      });
    })
  }

}
