import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { ReferansComponent } from '../referans.component';

@Component({
  selector: 'app-referans-help',
  templateUrl: './referans-help.component.html',
  styleUrls: ['./referans-help.component.css'],
  providers: [ReferansComponent]
})
export class ReferansHelpComponent implements OnInit {
  db: AngularFirestore;
  detailItem: any;
 
  constructor(private route: ActivatedRoute, db: AngularFirestore) {
    this.db = db;
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.db.doc('isler/' + id).get().subscribe((doc) => {
      if (doc.exists) {
        this.detailItem = doc.data()
      }
    })

    
    this.route.params.subscribe(params => {


    })
  }
}
