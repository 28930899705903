import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-kullanici-giris',
  templateUrl: './kullanici-giris.component.html',
  styleUrls: ['./kullanici-giris.component.css']
})
export class KullaniciGirisComponent implements OnInit {
  items: Observable<any[]>;

  constructor(db: AngularFirestore) { 
    this.items = db.collection('isler').valueChanges()
    db.collection('isler').valueChanges().forEach(f=>{
      console.log(f)
    })
    console.log(this.items)
  }

  ngOnInit() {
  }

}
