import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AnasayfaComponent} from './anasayfa/anasayfa.component';
import {TeknikbilgiComponent} from './teknikbilgi/teknikbilgi.component';
import {templateJitUrl} from '@angular/compiler';
import {ReferansComponent} from './referans/referans.component';
import {ReferansHelpComponent} from './referans/referans-help/referans-help.component';
import {ReferansInsaatComponent} from './referans-insaat/referans-insaat.component';
import {VizyonComponent} from './vizyon/vizyon.component';
import {UrunlerComponent} from './urunler/urunler.component';
import {UrunDetayComponent} from './urun-detay/urun-detay.component';
import {IletisimComponent} from "./iletisim/iletisim.component";


const routes: Routes = [
  {path: 'anasayfa', component: AnasayfaComponent},
  {path: 'teknikbilgi', component: TeknikbilgiComponent},
  {path: 'referans', component: ReferansComponent},
  {path: 'referans-detay/:id', component: ReferansHelpComponent},
  {path: 'referans-insaat', component: ReferansInsaatComponent},
  {path: 'iletisim',component: IletisimComponent },
  {path: 'vizyon', component: VizyonComponent},
  {
    path: 'urunler', component: UrunlerComponent,
    children: [
      {path: ':name', component: UrunDetayComponent}
    ]
  },
  {path: '**', redirectTo: '/anasayfa', pathMatch: 'full'}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
